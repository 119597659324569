<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">{{ product.productName }}</h1>
      <progressIndicator :step="product.step" :steps="product.steps" />
    </div>
    <template v-if="product.step == 1">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="_fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep1"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Fejlbesked -->
        <message
          v-show="error.step1"
          :message="error.step1"
          type="warning"
          v-on:formFunction="formFunction"
        />

				<!-- Infobesked -->
				<message
            :message="_info_box"
            type="info"
            v-on:formFunction="formFunction"
          />
      </div>
    </template>
    <template v-else-if="product.step == 2">
      <form id="step2" v-on:submit.prevent="submitStep2">

        <!-- Pakkekomponent -->
        <packages
					class="small"
          altTitle="Vælg din pakke"
          altLinkText="Læs mere om vores rejseforsikring"
          :packages="product.packages"
          :packageLink="product.packageLink"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        >
					<template v-slot:subtitle>
						<div style="text-align: center; margin: 0 0 60px 0;">
							Selvrisiko: 0 kr.<br><br>
							<b>Vær opmærksom på, at selvrisikoen på eftersøgning og redning afviger fra ovenstående selvrisiko.</b>
						</div>
					</template>
				</packages>

        <!-- Extra packages -->
        <coverages
          v-show="Object.keys(_coverages).length != 0"
          v-model="product.coverages"
          :requirementsMet="simpleRequirementsMet"
          v-on:packageChoice="choosePackage"
        >
        </coverages>

        <div class="container small">
          <!-- Infobesked -->
          <message
            v-show="info.step2"
            :message="info.step2"
            type="info"
            v-on:formFunction="formFunction"
          />

          <!-- Fejlbesked -->
          <message
            v-show="error.step2"
            :message="error.step2"
            type="warning"
            v-on:formFunction="formFunction"
          />
        </div>
      </form>
    </template>

    <!-- Buttons on bottom -->
    <div class="container small">
      <ProgressButtons
        :step="product.step"
        :steps="product.steps"
        :existingPolicy="existingPolicy"
        @changeStep="changeStep"
      />
    </div>

    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :objectCode="product.objectCode"
      :productId="product.productId"
      :productLink="product.productLink"
      v-on:contactFromFooter="messageBox"
      alt-title="Mere om vores rejseforsikring"
    >
      Med vores rejseforsikring er du i sikre hænder, når du rejser afsted på ferie, backpacking, studietur eller lignende i Europa eller verden. Vores rejseforsikring dækker også rejser i Danmark, fx hvis du mister din bagage, og det samme gør sig gældende for vores afbestillingsforsikring, som er et tilvalg til din rejseforsikring.
    </product-footer>
  </div>
</template>
<script>
import P60 from "@/assets/json/P60.json";
import FormInput from "@/components/FormInput.vue";
import progressIndicator from "@/components/progress-indicator.vue";
import ProgressButtons from "@/components/ProgressButtons.vue";
import message from "@/components/message.vue";
import productFooter from "@/components/product-footer.vue";
import packages from "@/components/packages.vue";
import FormStep from "@/components/FormStep.vue";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";
import debounce from "lodash/debounce";
import coverages from "@/components/coverages.vue";
import dayjs from "dayjs";

export default {
  name: "P60",
  components: {
    FormInput,
    progressIndicator,
    ProgressButtons,
    message,
    productFooter,
    packages,
    FormStep,
    coverages,
  },
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  mixins: [commonProduct, validateData],
  data() {
    return {
      productId: "P60",
      product: this.copy(P60),
      temporaryProduct: this.copy(this.$store.state.temporaryProduct),
    };
  },
  created() {
    /*
     * If you edit and existing offer copy the policy here, or if you have temporary object
     */
    if (this.policy) {
      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      this.product = this.copy(this.policy.value);
      //this.existingPolicy = true;
      //this.existingPolicyKey = this.policy.key;
    } else if (
      !this.$route.query.input &&
      this.temporaryProduct &&
      this.temporaryProduct.productId == this.productId
    ) {
      this.product = this.copy(this.temporaryProduct);
    }
  },
  mounted() {
    this.scrollToTop()
    // Function if input has come from website
    if (this.$route.query.input) {
      // Get query and delete input
      let query = this.copy(this.$route.query);
      query.input = undefined;

      // Push on without input
      this.$router
        .push({ name: "rejseforsikring", query: query })
        .catch(() => {});
    }
  },
  computed: {
		_info_box() {
      return {
        headline: "Skal du ud at rejse mere end 60 dage?",
        text: "Skal du rejse i mere end 60 dage, kan du udvide din rejseforsikring helt op til 180 dage. Du skal dog kun betale for lige præcis det antal ekstra dage, som du har brug for. Skal du fx afsted på en rejse, der varer 65 dage, skal du altså kun tilkøbe fem ekstra dage. Vær opmærksom på, at de ekstra rejsedage skal tilkøbes særskilt for hver enkelt rejsende.",
        button: {
          text: "Kontakt mig",
          function: "messageBox",
        },
      };
    },
    simpleRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this._fields)) {
        if (value.required) {
          if (value.value === null || typeof value.value == 'undefined' || value.error) {
            this.cerror("Not all requirements are met", value);
            return false;
          }
        }
      }

      return true;
    },  
    /*
     * Check requirements
     */
    allRequirementsMet() {     
      // eslint-disable-next-line no-unused-vars
      if (!this.simpleRequirementsMet) return false;

      // Extra tests
      //if (this.product.coverages.sick.chosen && (this.product.fields.coverageSick.value === null || this.product.fields.coverageSick.value === false)) return false;
      //if (this.product.coverages.death.chosen && (this.product.fields.sum.value === null || this.product.fields.sum.value === false || this.product.fields.sum.value == "")) return false;
    
      return true;
    },
    _fields() {
      return this.product.fields;
    },
    _coverages() {
			return this.product.coverages;


      let r = {};

      /*if (this.product.fields.age.value >= 6 && this.product.fields.insurance.value === false || this.product.fields.vaccinated.value === false) return r;
      this.clog(this.product.fields.age.value, this.product.fields.insurance.value);*/

      for (const name in this.product.coverages) {
        r[name] = this.product.coverages[name];

        /*if (name == "death") {
          r[name].blocked = !this.product.coverages.sick.chosen;
        }*/
      }

      return r;
    },
  },
  watch: {
    "product.fields": {
      handler: function () {
        this.calculateEveryPrice();

        // Sick or injured
        /*if (this.product.fields.sick.value === true) {
          this.error[`step1`] = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Vi kan desværre ikke beregne en pris online på baggrund af de indtastede oplysninger. Når du udfylder vores kontaktformular, bliver du kontaktet af vores kundeservice med et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };
        } else {
          this.error[`step1`] = null;
        }*/

        // Too expensive
        /*if (this.product.fields.sum.value > 20000) {
          this.error[`step2`] = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Vi kan desværre ikke beregne en pris online på baggrund af de indtastede oplysninger. Når du udfylder vores kontaktformular, bliver du kontaktet af vores kundeservice med et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };
        } else {
          this.error[`step2`] = null;
        }*/
      },
      deep: true,
    },
    "product.coverages": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    }
  },
  methods: {
    /*
     * Reset zipcode
     */
    resetZipcode() {
      this.product.fields.zipCode.value = null;
    },
    /*
     * Set zipcode
     */
    setZipcode(value) {
      this.product.fields.zipCode.value = value.postnummer.nr;
    },
    /*
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {		
      this.clog("calc price");
      let self = this;

      // Update temporary product if product is not existing
      if (!self.existingPolicy) {
        self.$store.commit("temporaryProduct", self.product);
      }

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) return;

      // Remove error
      self.error.price = null;

      self.product.steps[1].disabled = true;

      // Get submit data without coverages
      let submitData = self.parseSubmitData(self.product);

      // Console log if in dev, test or beta environment
      this.clog('data', submitData);

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;
      

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then((response) => {
          self.insertPrice(response.data);

          self.product.steps[1].disabled = false;
        })
        .then(() => {
          // Show popup
          self.$store.commit("showPopup");

          // Update temporary product if product is not existing
          if (!self.existingPolicy) {
            self.$store.commit("temporaryProduct", self.product);
          }

          // If exiting policy, update existing policy
          if (self.existingPolicy) {
            self.updateExistingPolicy(self.product, self.existingPolicyKey);
          }
        })
        .catch(function () {
          // Show error
          self.error.price = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };

          self.product.steps[1].disabled = false;

          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),
    /*
     * Submit step 1 in form
     */
    async submitStep1() {
      let self = this;

      //*****************************************
      // CLEANUP
      //*****************************************

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      //*****************************************
      // MESSAGE BOXES
      //*****************************************

      if (this.product.fields.age.value < 18) {
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Ud fra den oplyste alder, kan vi desværre ikke beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }

      if (this.product.fields.claimsLast5Years.value > 4 || this.product.fields.personsInHousehold.value < 1 || ((this.product.fields.houseShare.value && this.product.fields.personsInHousehold.value > 3) || (!this.product.fields.houseShare.value && this.product.fields.personsInHousehold.value > 10))) {
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Vi kan desværre ikke beregne en pris online på baggrund af de indtastede oplysninger. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }

      //*****************************************
      // GENERIC SUBMIT
      //*****************************************

      // Set step 1 to loading
      self.product.steps[0].loading = true;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set loading to false
      self.product.steps[0].loading = false;
    },
    /*
     * Submit step 2 in form
     */
    submitStep2() {
      // Block cart of exp dog
      //if (this.product.fields.sum.value > 20000) return;

      // Verify selection
      /*if (this.product.coverages.sick.chosen && (this.product.fields.coverageSick.value === null || this.product.fields.coverageSick.value == '')) {
        this.product.fields.coverageSick.error = "Du skal vælge din selvrisiko";
        return;
      }*/

      /*if (this.product.coverages.death.chosen && (this.product.fields.sum.value === null || this.product.fields.sum.value == '')) {
        this.product.fields.sum.error = "Du skal indtaste prisen, du har betalt for din hund";
        return;
      }*/

      // Check if policy is exitting or new
      if (this.existingPolicy) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      } else {
        this.$store.commit("addProduct", this.product);
      }

      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      // Route to shopping basket
      this.$router.push({ name: "Kurv" });
    },
    /*
     * Update existing policy
     */
    updateExistingPolicy(product, key) {
      this.$store.commit("updateProduct", {
        value: product,
        key: key,
      });
    },
  },
};
</script>
